import React from 'react';

import { SCHEMA_TYPE_ENUM, SCHEMA_TYPE_MULTI_ENUM } from '../../util/types';
import { convertCategoriesToSelectTreeOptions, constructQueryParamName } from '../../util/search';

import SelectSingleFilter from './SelectSingleFilter/SelectSingleFilter';
import SelectMultipleFilter from './SelectMultipleFilter/SelectMultipleFilter';
import BookingDateRangeFilter from './BookingDateRangeFilter/BookingDateRangeFilter';
import KeywordFilter from './KeywordFilter/KeywordFilter';
import PriceFilter from './PriceFilter/PriceFilter';

/**
 * FilterComponent is used to map configured filter types
 * to actual filter components
 */
const FilterComponent = props => {
  const {
    idPrefix,
    config,
    urlQueryParams,
    initialValues,
    getHandleChangedValueFn,
    listingCategories,
    marketplaceCurrency,
    intl,
    ...rest
  } = props;
  // Note: config can be either
  // - listingFields config or
  // - default filter config
  // They both have 'key' and 'schemaType' included.
  const { key, schemaType } = config;
  const { liveEdit, showAsPopup } = rest;

  const useHistoryPush = liveEdit || showAsPopup;
  const prefix = idPrefix || 'SearchPage';
  const componentId = `${prefix}.${key.toLowerCase()}`;
  const name = key.replace(/\s+/g, '-').toLowerCase();

  // Default filters: price, keywords, dates
  // eslint-disable-next-line default-case
  switch (schemaType) {
    case 'category': {
      const { scope, isNestedEnum, nestedParams } = config;
      const queryParamNames = nestedParams?.map(p => constructQueryParamName(p, scope));

      const [categoryLevel1, categoryLevel2, categoryLevel3] = queryParamNames || [];

      const convertCategoriesToSelectTreeOptionsWithoutLastLevel = categories => {
        return categories.map(category => {
          const { id, name, subcategories } = category;
          if (
            subcategories &&
            subcategories.length > 0 &&
            subcategories[0].subcategories &&
            subcategories[0].subcategories.length > 0
          ) {
            return {
              option: id,
              label: name,
              suboptions: convertCategoriesToSelectTreeOptionsWithoutLastLevel(subcategories),
            };
          }
          return {
            option: id,
            label: name,
          };
        });
      };

      const getSelectedLevel2Category = (categories, selectedLevel1, selectedLevel2) => {
        const selectedLevel1Category = categories.find(cat => cat.id === selectedLevel1);
        if (selectedLevel1Category) {
          return selectedLevel1Category.subcategories.find(cat => cat.id === selectedLevel2);
        }
        return null;
      };

      const getBrandsForSelectedLevel2 = selectedLevel2Category => {
        if (
          selectedLevel2Category &&
          selectedLevel2Category.subcategories &&
          selectedLevel2Category.subcategories.length > 0
        ) {
          return selectedLevel2Category.subcategories.map(brand => ({
            option: brand.id,
            label: brand.name,
          }));
        }
        return [];
      };

      return (
        <>
          <SelectSingleFilter
            id={componentId}
            name={key}
            label={intl.formatMessage({ id: 'FilterComponent.categoryLabel' })}
            queryParamNames={[categoryLevel1, categoryLevel2]}
            initialValues={initialValues([categoryLevel1, categoryLevel2], liveEdit)}
            onSubmit={getHandleChangedValueFn(useHistoryPush)}
            options={convertCategoriesToSelectTreeOptionsWithoutLastLevel(listingCategories)}
            isNestedEnum={isNestedEnum}
            {...rest}
          />
          {urlQueryParams[categoryLevel1] && urlQueryParams[categoryLevel2] && (
            <SelectMultipleFilter
              id={`${componentId}.brands`}
              label="Brands"
              name="brands"
              queryParamNames={[categoryLevel3]}
              initialValues={initialValues([categoryLevel3], liveEdit)}
              onSubmit={getHandleChangedValueFn(useHistoryPush)}
              options={getBrandsForSelectedLevel2(
                getSelectedLevel2Category(
                  listingCategories,
                  urlQueryParams[categoryLevel1],
                  urlQueryParams[categoryLevel2]
                )
              )}
              schemaType="enum"
              {...rest}
            />
          )}
        </>
      );
    }
    case 'price': {
      const { min, max, step } = config;
      return (
        <PriceFilter
          id={componentId}
          label={intl.formatMessage({ id: 'FilterComponent.priceLabel' })}
          queryParamNames={[key]}
          initialValues={initialValues([key], liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          min={min}
          max={max}
          step={step}
          marketplaceCurrency={marketplaceCurrency}
          {...rest}
        />
      );
    }
    case 'keywords':
      return (
        <KeywordFilter
          id={componentId}
          label={intl.formatMessage({ id: 'FilterComponent.keywordsLabel' })}
          name={name}
          queryParamNames={[key]}
          initialValues={initialValues([key], liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...rest}
        />
      );
    case 'dates': {
      const { dateRangeMode } = config;
      const isNightlyMode = dateRangeMode === 'night';
      return (
        <BookingDateRangeFilter
          id={componentId}
          label={intl.formatMessage({ id: 'FilterComponent.datesLabel' })}
          queryParamNames={[key]}
          initialValues={initialValues([key], liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          minimumNights={isNightlyMode ? 1 : 0}
          {...rest}
        />
      );
    }
  }

  // Custom extended data filters
  switch (schemaType) {
    case SCHEMA_TYPE_ENUM: {
      const { scope, enumOptions, filterConfig = {} } = config;
      const { label, filterType } = filterConfig;
      const queryParamNames = [constructQueryParamName(key, scope)];
      return filterType === 'SelectSingleFilter' ? (
        <SelectSingleFilter
          id={componentId}
          label={label}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          options={enumOptions}
          isNestedEnum={false}
          {...rest}
        />
      ) : (
        <SelectMultipleFilter
          id={componentId}
          label={label}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          options={enumOptions}
          schemaType={schemaType}
          {...rest}
        />
      );
    }
    case SCHEMA_TYPE_MULTI_ENUM: {
      const { scope, enumOptions, filterConfig = {} } = config;
      const { label, searchMode } = filterConfig;
      const queryParamNames = [constructQueryParamName(key, scope)];
      return (
        <SelectMultipleFilter
          id={componentId}
          label={label}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          options={enumOptions}
          schemaType={schemaType}
          searchMode={searchMode}
          {...rest}
        />
      );
    }
    default:
      return null;
  }
};

export default FilterComponent;
